import React from "react";
import SliderCard from "../SliderCard/SliderCard";
import {
  SliderWrapper,
  CardPageWrapper,
  RoomName,
  DekInfo,
  RoomsCont,
  OtherRooms,
  BottomCardWrapper,
  MainWrapperCardPage,
  BlueLine,
  GenInfo,
} from "./CardPage.style";
import { RoomContext } from "../../Context/RoomsContext";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import img1 from "../../assets/CardPageImg/LISimg1.jpg";
import HotelCard from "../HotelCard/HotelCard";
import img2 from "../../assets/CardPageImg/LISimg2.jpg";
import sky11 from "../../assets/Sky 1/ski11.JPG"
import sky12 from "../../assets/Sky 1/ski12.JPG"
import sky13 from "../../assets/Sky 1/ski13.JPG"
import sky14 from "../../assets/Sky 1/ski14.JPG"
import sky16 from "../../assets/Sky 1/ski16.JPG"
import sky17 from "../../assets/Sky 1/ski17.JPG"
import sky18 from "../../assets/Sky 1/ski18.JPG"
import sky22 from "../../assets/Sky 2/ski22.jpg"
import sky23 from "../../assets/Sky 2/ski23.jpg"
import sky24 from "../../assets/Sky 2/ski24.jpg"
import sky25 from "../../assets/Sky 2/ski25.jpg"
import sky26 from "../../assets/Sky 2/ski26.jpg"
import sky27 from "../../assets/Sky 2/ski27.jpg"
import sky28 from "../../assets/Sky 2/ski28.jpg"
import sky31 from"../../assets/Sky 3/ski31.jpg"
import sky32 from"../../assets/Sky 3/ski32.jpg"
import sky33 from"../../assets/Sky 3/ski33.jpg"
import sky34 from"../../assets/Sky 3/ski34.jpg"
import sky35 from"../../assets/Sky 3/ski35.jpg"
import sky36 from"../../assets/Sky 3/ski36.jpg"
// import sky41 from"../../assets/Sky 4/ski41.JPG"
// import sky42 from"../../assets/Sky 4/ski42.JPG"
// import sky43 from"../../assets/Sky 4/ski43.JPG"
// import sky44 from"../../assets/Sky 4/ski44.JPG"
// import sky45 from"../../assets/Sky 4/ski45.JPG"
// import sky46 from"../../assets/Sky 4/ski46.JPG"
// import sky47 from"../../assets/Sky 4/ski47.JPG"
// import sky48 from"../../assets/Sky 4/ski48.JPG"
import sky51 from"../../assets/Sky 5/ski51.jpg"
import sky52 from"../../assets/Sky 5/ski52.jpg"
import sky53 from"../../assets/Sky 5/ski53.jpg"
import sky54 from"../../assets/Sky 5/ski54.jpg"
import sky55 from"../../assets/Sky 5/ski55.jpg"
import sky56 from"../../assets/Sky 5/ski56.jpg"
import sky57 from"../../assets/Sky 5/ski57.jpg"

import sky61 from"../../assets/Sky 6/ski61.jpg"
import sky62 from"../../assets/Sky 6/ski62.jpg"
import sky63 from"../../assets/Sky 6/ski63.jpg"
import sky64 from"../../assets/Sky 6/ski64.jpg"
import sky65 from"../../assets/Sky 6/ski65.jpg"
import sky66 from"../../assets/Sky 6/ski66.jpg"
import sky67 from"../../assets/Sky 6/ski67.jpg"
import sky68 from"../../assets/Sky 6/ski68.jpg"
import sky71 from"../../assets/Sky 7/ski71.jpg"
import sky72 from"../../assets/Sky 7/ski72.jpg"
import sky73 from"../../assets/Sky 7/ski73.jpg"
import sky74 from"../../assets/Sky 7/ski74.jpg"
import sky75 from"../../assets/Sky 7/ski75.jpg"
import sky81 from"../../assets/Sky 8/ski81.jpg"
import sky82 from"../../assets/Sky 8/ski82.jpg"
import sky83 from"../../assets/Sky 8/ski83.jpg"
import sky84 from"../../assets/Sky 8/ski84.jpg"
import sky85 from"../../assets/Sky 8/ski85.jpg"
import sky86 from"../../assets/Sky 8/ski86.jpg"
import Button from "@mui/material/Button";
import { BookBtn } from "./CardPage.style";
import CalendarComponent from "../Calendar/Calendar";
import { Box, Modal } from "@mui/material";
import ContactForm from "../ContactForm/ContactForm";
import api from "../../helpers/api";
import Loading from "../Loading";

const CardPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  // const [slides1, setSlides] = useState([]);
// console.log(id);

  const slides = getSkyArrayById(id)

  console.log(slides);
  
  const [showModal, setShowModal] = useState(false);
  const { room, setRoom } = useContext(RoomContext);
  const { selectedDates } = useContext(RoomContext);
  const [cottages, setCottages] = useState([]);
  const [selectedDateError, setSelectedDateError] = useState(false);
  function getSkyArrayById(id) {
    console.log(id);
    
    switch (id) {
      case "1":
        return [sky11, sky12, sky13, sky14, sky16, sky17, sky18];
      case "2":
        return [ sky22, sky23, sky24, sky25, sky26, sky27, sky28];
      case '3':
        return [sky31, sky32, sky33, sky34, sky35, sky36];
      case '4':
        return [];
      case '5':
        return [sky51, sky52, sky53, sky54, sky55, sky56, sky57];
      case '6':
        return [sky61, sky62, sky63, sky64, sky65, sky66, sky67, sky68];
      case '7':
        return [sky71, sky72, sky73, sky74, sky75, ];
      case '8':
        return [sky81, sky82, sky83, sky84, sky85, sky86];
      default:
        return [];
    }
  }
  useEffect(() => {

    (async () => {
      const data  = [
        {
          id:1,
          name:'Sky 1',
          price:""
        },
        {
          id:2,
          name:'Sky 2',
          price:""
        },
        {
          id:3,
          name:'Sky 3',
          price:""
        },
        {
          id:4,
          name:'Sky 4',
          price:""
        },
        {
          id:5,
          name:'Sky 5',
          price:""
        },
        {
          id:6,
          name:'Sky 6',
          price:""
        },
        {
          id:7,
          name:'Sky 7',
          price:""
        },
        {
          id:8,
          name:'Sky 8',
          price:""
        },
      ]

      setRoom( data[id - 1]);
      setCottages(data);
    })();
  }, [id]);
  const handleBooking = () => {
    if (!selectedDates.startDate) {
      setSelectedDateError(true);

      return;
    }
    setShowModal(true);
  };
  return (
    <MainWrapperCardPage>
      <CardPageWrapper>
        <SliderWrapper>
          <SliderCard images={slides} />
        </SliderWrapper>
        <RoomName>{room.name}</RoomName>
        <DekInfo>
          <GenInfo>
            <p>{t("dek")}</p>
          </GenInfo>
          <h1>{t("comfort")}</h1>
        </DekInfo>
        <GenInfo>
          <p>֊ {t("bed")}</p>
          <p>- {t("rope")}</p>
          <p>֊ {t("pool")}</p>
          <p>֊ {t("picnic")}</p>
          <p>֊ {t("cond")}</p>
          <p>֊ {t("bath")}</p>
          <p>֊ {t("wifi")}</p>
        </GenInfo>
        <BookBtn>
          {/* <CalendarComponent
            selectedDateError={selectedDateError}
            setSelectedDateError={setSelectedDateError}
            cottageId={room.id}
          />
          <Button
            variant="contained"
            style={{ height: "3rem", fontWeight: " bold", marginTop: "2rem" }}
            onClick={handleBooking}
          >
            Check pricing and Book here
          </Button>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
              onClick: () => setShowModal(false),
            }}
            sx={{
              width: "500px",
              margin: "0 auto",
              "@media (max-width: 600px)": {
                width: "88%",
              },
            }}
          >
            <Box>
              <ContactForm
                selectedDates={selectedDates}
                setShowModal={setShowModal}
                cottage={room}
              />
            </Box>
          </Modal> */}
          <p>Check-in 14:00</p>
          <p>Check-out 12:00</p>
        </BookBtn>
      </CardPageWrapper>
      <RoomsCont>
        <OtherRooms>
          <BlueLine />
          <h1>Other Rooms</h1>
        </OtherRooms>
        <BottomCardWrapper>
          {cottages.length > 0 ? (
            cottages.map(
              (room) =>
                // eslint-disable-next-line eqeqeq
                room.id != id && <HotelCard key={room.id} room={room} />
            )
          ) : (
            <Loading />
          )}
        </BottomCardWrapper>
      </RoomsCont>
    </MainWrapperCardPage>
  );
};

export default CardPage;
