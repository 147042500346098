export default {
  home: "Գլխավոր",
  about: "Մեր մասին",
  gallery: "Նկարներ",
  contacts: "Հետադարձ կապ",
  booking: "Ամրագրում",
  our_rooms: "Մեր Տնակները",
  call_us: "Զանգահարեք",
  our_contacts: "Մեր Կոնտակտները",
  village_yenokavan: "գյուղ Ենոքավան.",
  district: "Տավուշի մարզ, Հայաստան",
  call: "Հեռախոս",
  cost: "Դրամ",
  book: "ամրագրել",
  dek: "Մեկ ննջասենյակ, երկու մահճակալ․ Մակերեսը 24 քառակուսի մետր + պատշգամբ , մինչև ֊ 4 Հյուր․",
  comfort:"Մեր Հարմարությունները",
  bed:"ապակյա ննջասենյակ",
  cond:"կոնդիցիոներ",
  bath:"սեփական լոգարան",
  pool:"բացօդյա ջակուզի",
  wifi:"անվճար WIFI",
  picnic:"բացօդյա հարթակ",
  rope:"գամակ",
  follow:"Հետևեք Մեզ",
  check_in: "Ժամանման օր",
  check_out: "Մեկնման օր",
  services:"Ծառայություններ",
  wrong_input: "Սխալ Ընտրություն",
  select_dates: "Ընտրեք Օրերը",
  calendar_placeholder: "Ընտրել Օրերը",
  first_day: "Առաջին օր",
  last_day: "Վերջին օր",
  first_name: "Անուն",
  last_name: "Ազգանուն",
  phone: "Հեռախոս",
  contact: "Կապ",
  total_price: "Ընդհանուր Գինը",
  success_submit:"Ամրագրումն Հաստատված է"
};