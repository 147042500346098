export default {
  home: "HOME",
  about: "ABOUT",
  gallery: "GALLERY",
  contacts: "CONTACTS",
  booking: "BOOKING",
  our_rooms: "Our Cottages",
  call_us: "Call Us",
  our_contacts: "Our Contacts",
  village_yenokavan: "village Yenokavan.",
  district: "Tavush district, Armenia",
  call: "call",
  cost: "AMD",
  follow:"Follow",
  book:"Book now",
  check_in: "Check in",
  check_out: "Check out",
  services:"services",
  wrong_input: "Wrong Input",
  select_dates: "Select The Dates",
  calendar_placeholder: "Select Days",
  first_day: "First Day",
  last_day: "Last Day",
  first_name: "First Name",
  last_name: "Last Name",
  phone: "Phone",
  contact: "Contact",
  total_price: "Total Price",
  success_submit:"Reservation Submited"
};