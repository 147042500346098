export default {
  locale: 'hy',
  months: [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր'
  ],
  weekdaysShort: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շաբ'],
  weekdaysLong: [
    'Կիրակի',
    'Երկուշաբթի',
    'Երեքշաբթի',
    'Չորեքշաբթի',
    'Հինգշաբթի',
    'Ուրբաթ',
    'Շաբաթ'
  ],
};
